<template>
  <!-- Template for automated creation of *Browse.vue components by CodeLaravel. -->
  <v-container fluid @keyup.enter="doSearch()" @keyup.escape="close()">
    <v-data-table
      class="elevation-1 zebra"
      :footer-props="footerProps"
      :headers="headers"
      :hide-default-footer="hideHeader"
      :hide-default-header="hideHeader"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItems"
    >
      <template v-slot:item.inactive="{ item }">
        <v-simple-checkbox v-model="item.inactive" disabled></v-simple-checkbox>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{ $helper.tRoute($route.name) }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-col cols="3" class="mt-4">
            <v-select
              v-model="searchIn"
              id="searchIn"
              :items="searchInItems"
              :label="$t('searchIn')"
            ></v-select>
          </v-col>
          <v-col cols="3" class="mt-4">
            <v-text-field
              v-model="search"
              autofocus
              id="search"
              clearable
              :label="$t('search')"
            ></v-text-field>
          </v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="doSearch()"
                fab
                id="doSearch"
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>fas fa-search</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("search") }}</span>
          </v-tooltip>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip bottom>
            <template v-if="allowed(`${table}.create`)" v-slot:activator="{ on, attrs }">
              <v-btn
                @click="push(`${table}.create`)"
                fab
                id="create"
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $helper.tRoute(`${table}.create`) }}</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip v-for="action in actions" :key="action.route" top>
          <template v-if="allowed(action.route)" v-slot:activator="{ on, attrs }">
            <v-icon
              @click="push(action.route, item)"
              :id="actionId(action.route, item)"
              :color="color"
              left
              small
              v-bind="attrs"
              v-on="on"
            >
              {{ action.icon }}
            </v-icon>
          </template>
          <span>{{ $helper.tRoute(action.route) }}</span>
        </v-tooltip>
      </template>
      <template slot="no-data">
        <div>{{ noResultsText }}</div>
      </template>
    </v-data-table>
    <v-alert v-model="showAlert" dense dismissible type="error">{{ message }}</v-alert>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Browse",
  props: ["columns", "formats", "itemsPerPage", "searchOnStart", "table"],

  data() {
    return {
      actions: [
        { icon: "fas fa-eye", route: `${this.table}.show` },
        { icon: "fas fa-pencil-alt", route: `${this.table}.edit` },
        { icon: "fas fa-clone", route: `${this.table}.clone` },
        { icon: "fas fa-trash-alt", route: `${this.table}.delete` },
      ],

      footerProps: {
        "items-per-page-options": this.$settings["items-per-page-options"],
        "show-current-page": this.$settings["show-current-page"],
        "show-first-last-page": this.$settings["show-first-last-page"],
      },

      hideHeader: true,
      items: [],
      loading: false,
      message: "",
      noData: null,

      options: {
        page: 1,
        itemsPerPage: parseInt(this.itemsPerPage),
        sortBy: [this.columns[0]],
        sortDesc: [false],
      },

      search: null,
      searchIn: "0",
      showAlert: false,
      totalItems: 0,
    };
  },

  computed: {
    ...mapGetters({
      allowed: "auth/allowedRoute",
    }),

    color() {
      return this.$settings.appColor;
    },

    noResultsText() {
      return this.hideHeader ? "" : this.$t("$vuetify.dataIterator.noResultsText");
    },

    headers() {
      let array = [];

      array.push({
        text: this.$t("actions"),
        value: "actions",
        sortable: false,
      });

      this.columns.forEach((element) => {
        array.push({
          text: this.$t("Cols." + this.table + "." + element),
          value: element,
        });
      });

      return array;
    },

    searchInItems() {
      let array = [];
      let i = 0;

      this.columns.forEach((element) => {
        array.push({
          text: this.$t("Cols." + this.table + "." + element),
          value: `${i}`,
        });

        i++;
      });

      return array;
    },
  },

  watch: {
    options: {
      handler(to, from) {
        if (
          to.page !== from.page ||
          to.itemsPerPage !== from.itemsPerPage ||
          to.sortBy !== from.sortBy ||
          to.sortDesc !== from.sortDesc
        ) {
          this.doSearch();
        }
      },
      deep: true,
    },

    $route: "fetch",
  },

  created() {
    if (!this.$route.query.page) {
      if (this.searchOnStart) {
        this.doSearch(); // Force search with default parameters when none provided.
      }
    } else {
      // Fetch data with parameters provided.
      this.search = this.$route.query.search;
      this.searchIn = this.$route.query.searchIn;
      this.options.page = Number(this.$route.query.page);
      this.options.itemsPerPage = Number(this.$route.query.perPage);
      this.options.sortBy[0] = this.headers[Number(this.$route.query.sortBy) + 1].value;
      this.options.sortDesc[0] = !this.$route.query.asc;
      this.fetch();
    }
  },

  methods: {
    actionId(route, item) {
      return route.replace(this.table + ".", "") + item.id;
    },

    close() {
      this.$router.go(-1);
    },

    doSearch() {
      // Fetch from page 1 on search and searchIn changes.
      if (
        this.search != this.$route.query.search ||
        this.searchIn != this.$route.query.searchIn
      ) {
        this.options.page = 1;
      }

      // Search by navigating to this component if parameters changed otherwise fetch data.
      this.$router
        .replace({ name: this.$route.action, query: this.searchParams() })
        .catch((e) => {
          if (e.name == "NavigationDuplicated") {
            this.fetch();
          } else {
            this.error(e);
          }
        });
    },

    error(e) {
      if (this.$helper.errorStatus(e) == 403) this.$router.replace({ name: "forbidden" });

      this.message =
        this.$t("browseError", [this.$helper.tTable(this.table, 2)]) +
        this.$t(this.$helper.error(e));

      this.showAlert = true;
    },

    fetch() {
      this.loading = true;
      this.showAlert = false;

      this.$api
        .browse(this.table, this.searchParams())
        .then((response) => this.setData(response.data))
        .catch((e) => this.error(e))
        .finally(() => (this.loading = false));
    },

    push(route, item) {
      if (item) {
        this.$router.push({ name: route, params: { id: item.id } });
      } else {
        this.$router.push({ name: route });
      }
    },

    searchParams() {
      return {
        search: this.search,
        searchIn: this.searchIn,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.headers.findIndex((h) => h.value === this.options.sortBy[0]) - 1,
        asc: !this.options.sortDesc[0],
      };
    },

    setData({ data: items, meta }) {
      this.hideHeader = false;
      this.$helper.formatItems(items, this.formats);
      this.items = items;
      this.totalItems = meta.total;
    },
  },
};
</script>
