var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }return _vm.close()}]}},[_c('v-data-table',{staticClass:"elevation-1 zebra",attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"hide-default-footer":_vm.hideHeader,"hide-default-header":_vm.hideHeader,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.inactive",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.inactive),callback:function ($$v) {_vm.$set(item, "inactive", $$v)},expression:"item.inactive"}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$helper.tRoute(_vm.$route.name)))]),_c('v-spacer'),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"id":"searchIn","items":_vm.searchInItems,"label":_vm.$t('searchIn')},model:{value:(_vm.searchIn),callback:function ($$v) {_vm.searchIn=$$v},expression:"searchIn"}})],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"autofocus":"","id":"search","clearable":"","label":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","id":"doSearch","x-small":""},on:{"click":function($event){return _vm.doSearch()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-search")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("search")))])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.allowed((_vm.table + ".create")))?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","id":"create","x-small":""},on:{"click":function($event){return _vm.push((_vm.table + ".create"))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-plus")])],1)]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$helper.tRoute((_vm.table + ".create"))))])])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.actions),function(action){return _c('v-tooltip',{key:action.route,attrs:{"top":""},scopedSlots:_vm._u([(_vm.allowed(action.route))?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":_vm.actionId(action.route, item),"color":_vm.color,"left":"","small":""},on:{"click":function($event){return _vm.push(action.route, item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(action.icon)+" ")])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$helper.tRoute(action.route)))])])})}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(_vm._s(_vm.noResultsText))])])],2),_c('v-alert',{attrs:{"dense":"","dismissible":"","type":"error"},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(_vm._s(_vm.message))]),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }